import React, { Component } from 'react';

import Logo from '../img/logos/logo_full.png';

import Flamingo from '../img/images/flamingo.jpeg';
import Leaves from '../img/images/leaves.jpeg';

import Facebook from '../img/socialIcons/facebook_2.png';
import Instagram from '../img/socialIcons/instagram_2.png';
import Soundcloud from '../img/socialIcons/soundcloud_2.png';
import Youtube from '../img/socialIcons/youtube_2.png';

function scrollWin() {
  window.scrollTo(0, 0);
}

class Splash extends Component {

  render() {
    scrollWin();
    return (
      <div className="splashWrapper">
        <div className='border'></div>
        <div className='container'>
        <img id="logo" src={Logo} alt="DJ Trovão logo" />
        <h1>Feel-good, groovy house music</h1>
            <a href="mailto:info@djtrovao.com">info@djtrovao.com</a>
            {/* <a href="mailto:info@djtrovao.com">presskit</a> */}
            <div className="socialIcons">
              <a href="https://soundcloud.com/djtrovaonl"><img src={Soundcloud} alt="Soundcloud icon" /></a>
              <a href="https://www.instagram.com/djtrovaonl/"><img src={Instagram} alt="Instagram icon" /></a>
              <a href="https://www.facebook.com/djtrovaonl/"><img src={Facebook} alt="Facebook icon" /></a>
              <a href="https://www.youtube.com/channel/UCfCEECZ2Egx1QN9D1BVnhcg"><img src={Youtube} alt="Youtube icon" /></a>
          </div>
        <p>DJ Trovão <br></br>
        Kylian Gieles <br></br>
Nieuweweg 3 <br></br>
4011JV Zoelen <br></br>
KVK 91233070 <br></br>
BTW NL004875296B68 <br></br><br></br>
2024 © All rights reserved</p>
        </div>
        <img src={Leaves} id="leaves"/>
        <img src={Flamingo} id="flamingo"/>
      </div>
    );
  }
}

export default Splash;