import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

//Pages
import Splash from './pages/splash';

//Style
import './style/style.scss';

function App() {
  return (
    <Router>
      <div className="App">
        <Splash />
      </div>
    </Router>
  );
}

export default App;
